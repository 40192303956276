import * as TE from 'fp-ts/lib/TaskEither';
import * as O from 'fp-ts/lib/Option';
import {pipe} from 'fp-ts/lib/function';
import AuthApi from '../api/AuthApi';
import {BSTask} from '../model/types';
import {User} from '../model/domain/User';
import {AuthUser} from '../model/domain/AuthUser';
import {debugIO} from '../util/logger';

const debugUser = (user: User): BSTask<void> =>
  debugIO('Authorized user', user);

const signInWithCredentials = (
  email: string,
  password: string,
): BSTask<User> => {
  return pipe(
    debugIO(`Performing SignIn with credentials by email=${email}`),
    TE.chain(() => AuthApi.signIn(O.some([email, password]))),
    TE.map((au: AuthUser) => au.user),
  );
};

const checkAuthWay = (email: string): BSTask<unknown> => {
  return pipe(
    debugIO('Check auth way'),
    TE.chain(() => AuthApi.checkAuthWay(email)),
  );
};

const signInWithToken = (): BSTask<User> => {
  return pipe(
    debugIO('SignIn with token'),
    TE.chain(() => AuthApi.signIn(O.none)),
    TE.map((au: AuthUser) => au.user),
    TE.chainFirst(debugUser),
  );
};

const resetPassword = (email: string): BSTask<void> =>
  pipe(
    debugIO('Sending reset password request email=' + email),
    TE.chain(() => AuthApi.resetPassword(email)),
  );

const signUpWithApplication = (
  email: string,
  password: string,
  username: string,
  application: string,
  inviteCode?: string,
): BSTask<User> =>
  pipe(
    debugIO('Sending sign up request email=' + email),
    TE.chain(() => AuthApi.signUp(email, password, username, application, inviteCode)),
    TE.chain(() => signInWithCredentials(email, password)),
  );

const signUpForInvited = (email: string, password: string, username: string, inviteCode?: string): BSTask<User> =>
  pipe(
    debugIO('Sending sign up request for invited email=' + email),
    TE.chain(() => AuthApi.signUpForInvited(email, password, username, inviteCode)),
    TE.chain(() => signInWithCredentials(email, password)),
  );

const signOut = (): BSTask<void> =>
  pipe(
    debugIO('Sending sign out password request'),
    TE.chain(() => {
      Object.keys(localStorage).forEach((key) => {
        if (key.startsWith('kc-callback')) {
          localStorage.removeItem(key);
        }
      });

      return AuthApi.signOut();
    }),
  );

const updatePassword = (secret: string, password: string): BSTask<void> =>
  pipe(
    debugIO('Sending update password request for secret=' + secret),
    TE.chain(() => AuthApi.updatePassword(secret, password)),
  );

const updPasswordWithCurrent = (
  email: string,
  newPassword: string,
  currentPassword: string,
): BSTask<void> =>
  pipe(
    debugIO('Sending update current password email=' + email),
    TE.chain(() =>
      AuthApi.updPasswordWithCurrent(email, newPassword, currentPassword),
    ),
  );

export default {
  signInWithCredentials,
  checkAuthWay,
  signInWithToken,
  resetPassword,
  signUpWithApplication,
  signUpForInvited,
  signOut,
  updatePassword,
  updPasswordWithCurrent,
};
