import {assertNever} from './util/assertNever';

interface AppConfig {
  appUUID: string;
  debugHttp: boolean;
  debugHtml: boolean;
  autoSignIn: boolean;
  baseName: string;
  apiUrl: string;
  redirectUrl: string;
  requestTimeout: number;
  answerTimeout: number;
  headwayAccount: string;
  googleTagManager: string;
}

const releaseConfig: AppConfig = {
  appUUID: 'ios-test-app',
  debugHttp: false,
  debugHtml: false,
  autoSignIn: true,
  baseName: '/app',
  // apiUrl: 'https://svc-dev.blankslatetechnologies.com/sreo/v0.1',
  apiUrl: (window as any).API_URL || 'https://svc.blankslatetechnologies.com/sreo/v0.1',
  redirectUrl: 'https://app.blankslatetechnologies.com/',
  requestTimeout: 30000,
  answerTimeout: 60000,
  headwayAccount: 'Jljo4x',
  googleTagManager: 'GTM-MV4VNJG',
};

const devConfig: AppConfig = {
  ...releaseConfig,
  apiUrl: (window as any).API_URL || 'https://svc-dev.blankslatetechnologies.com/sreo/v0.1',
  redirectUrl: 'https://app-dev.blankslatetechnologies.com/',
  googleTagManager: '',
  headwayAccount: '',
};

const localConfig: AppConfig = {
  ...releaseConfig,
  debugHttp: true,
  debugHtml: false,
  autoSignIn: true,
  baseName: '/app',
  // baseName: '/',
  apiUrl: 'https://svc-dev.blankslatetechnologies.com/sreo/v0.1',
  redirectUrl: 'https://app-dev.blankslatetechnologies.com/',
  // apiUrl: (window as any).API_URL || 'http://localhost/sreo/v0.1',
  answerTimeout: 10000,
};

type EnvType = 'local' | 'dev' | 'prod';

const getAppEnv = (): EnvType => {
  const currentHost = window.location.hostname;

  if (/.*localhost.*/.test(currentHost)) {
    return 'local';
  } else if (/.*-dev.*/.test(currentHost)) {
    return 'dev';
  } else {
    return 'prod';
  }
};

const getConfig = (): AppConfig => {
  const env: EnvType = getAppEnv();
  switch (env) {
    case 'local':
      //return localConfig;
      return devConfig;
    case 'dev':
      return devConfig;
    case 'prod':
      return releaseConfig;
    default:
      return assertNever(env);
  }
};

export const isDev = process.env.NODE_ENV === 'development';

export default getConfig();
