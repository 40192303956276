import React from 'react';
import { Chart } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ChartType,
  registerables,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { LinearProgress, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { TklStat } from '../model/domain/TklStat';
import Timer from '../assets/images/timer.svg';
import TaskList from '../assets/images/task_list.svg';
import {RingBlock} from "./Ring";

const useStyles = makeStyles((theme: Theme) => ({
  performance: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  statisticBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  statisticContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '160px',
    padding: '11px 8px',
    borderRadius: '28px',
    backgroundColor: theme.palette.secondary.main,
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.12)',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '36px',
    height: '36px',
    marginRight: '12px',
    borderRadius: '50%',
    backgroundColor: '#F4F7FE',
  },
  textContainer: {
    width: '93px',
  },
  titleText: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 500,
    color: '#848484',
    letterSpacing: '0.14px',
    lineHeight: '15.507px',
  },
  valueText: {
    fontFamily: 'Roboto',
    fontSize: '17px',
    fontWeight: 600,
    letterSpacing: '0.17px',
  },
  TKLBlock: {
    marginTop: '54px',
    marginBottom: '43px',
  },
  TKLText: {
    marginTop: '14px',
    textAlign: 'center',
    cursor: 'pointer',
  },
  chartContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  chartContainerShort: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  chartDetailsContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: 30,
  },
  teamTKLBlock: {
    width: '334px',
    padding: '16px 23px 21px',
    borderRadius: '20px',
    backgroundColor: '#fff',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.12)',
  },
  stateString: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22px',
    color: theme.palette.primary.main,
    marginTop: '0px',
    marginBottom: '0px',
  },
  colorGreen: {
    color: '#26c900',
  },
  colorRed: {
    color: '#ff0000',
  },
  colorBlue: {
    color: '#00d3ff',
  },
  statText: {
    fontFamily: 'Roboto Condensed',
    fontWeight: 400,
    fontSize: '27px',
    color: theme.palette.primary.main,
    marginTop: '0px',
    textAlign: 'center',
  },
  overallPerformanceCardGreen: {
    backgroundColor: '#26c900',
  },
  overallPerformanceCardRed: {
    backgroundColor: '#ff0000',
  },
  overallPerformanceCardGray: {
    backgroundColor: '#cccccc',
  },
  overallPerformanceCard: {
    padding: '3rem',
    borderRadius: '10px',
    marginBottom: '3rem',
    textAlign: 'left',
  },
  overallPerformanceBasicText: {
    fontFamily: 'RobotoCondensed-Regular',
    fontSize: '22px',
    textAlign: 'left',
    padding: '0px',
    margin: '0px',
  },
  overallPerformanceMiddleText: {
    fontFamily: 'BarlowSemiCondensed-Bold',
    fontSize: '22px',
    textAlign: 'left',
    marginBottom: '1rem',
  },
  overallPerformanceHeaderText: {
    textAlign: 'left',
    fontFamily: 'BarlowSemiCondensed-Bold',
    fontSize: '40px',
    margin: '0px',
  },
  overallPerformanceCardContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '10px',
  },
  overallPerformanceHead: {
    textAlign: 'center',
    fontFamily: 'BarlowSemiCondensed-Bold',
    fontSize: '40px',
    marginBottom: '1rem',
  },
  progressContainer: {
    marginTop: '10%',
    padding: 30,
    width: '100%',
  },
}));

interface LegacyPerformanceProps {
  stat?: TklStat;
  inProgress: boolean;
  leaderBoardEnabled?: boolean;
  openTKLPopup: () => void;
}

export const Performance: React.FC<LegacyPerformanceProps> = ({
  stat,
  inProgress,
  leaderBoardEnabled = false,
  openTKLPopup,
}) => {
  const classes = useStyles();

  if (inProgress) {
    return (
      <div className={classes.progressContainer}>
        <LinearProgress />
      </div>
    );
  } else if (!stat) {
    return (
      <div>
        <h5 className={classes.overallPerformanceHeaderText}>
          No User Statistic Found
        </h5>
      </div>
    );
  } else {
    ChartJS.register(...registerables, ChartDataLabels);

    const options = stat && {
      layout: {
        padding: {
          top: 40,
          bottom: 40,
          right: 30,
          left: 10,
        },
      },
      maintainAspectRatio: false,
      responsive: false,
      barThickness: 3,
      borderWidth: 0,
      borderSkipped: true,
      borderRadius: 90,
      barPercentage: 0.1,
      categoryPercentage: 0.5,
      minBarLength: 2,
      plugins: {
        tooltip: {
          enabled: false,
        },
        legend: {
          display: false,
        },
        datalabels: {
          anchor: 'start' as const,
          align: 'start' as const,
        },
      },
      scales: {
        x: {
          grid: {
            color: 'transparent',
            display: false,
            drawBorder: false,
          },
          ticks: {
            display: false,
          },
        },
        y: {
          min: -100,
          max: 100,
          ticks: {
            display: false,
          },
          grid: {
            display: false,
            drawBorder: false,
          },
        },
      },
      title: {
        display: true,
        text: 'Overall performance',
      },
    };

    const personalScoreBiggerOrEqual =
      Math.floor(stat.myTkl) >= Math.floor(stat.myTeamTkl);

    const emptyLine = {
      type: 'line',
      label: 'Line',
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
      backgroundColor: 'rgba(0, 0, 0, 1)',
      borderColor: '#00000026',
      borderWidth: 3,
      pointRadius: [0, 0, 0, 0, 5, 0, 0, 0, 0],
      pointBorderWidth: [0, 0, 0, 0, 4, 0, 0, 0, 0],
      pointBorderColor: '#FFF',
      pointBackgroundColor: '#CFCFD1',
      datalabels: {
        display: false,
      },
    };

    const datalabelsFont = [
      {
        size: 12,
      },
      {
        size: 12,
      },
      {
        size: 12,
      },
      {
        size: 12,
      },
      {
        size: 12,
        weight: 'bold',
      },
      {
        size: 12,
      },
      {
        size: 12,
      },
      {
        size: 12,
      },
      {
        size: 12,
      },
      {
        size: 12,
      },
      {
        size: 12,
      },
    ];

    const biggestTopScore = stat.topUsersTkl.reduce((acc, item) => {
      if (acc < item[1]) return item[1];
      return acc;
    }, 0);

    const getDataLabelsText = (value: number[], context: any) => {
      if (value === null) return;
      if (context.dataIndex === 4) return ['Team Avg ', stat.myTeamTkl + '%'];
      if (context.dataIndex === bestScorePosition) {
        const plusMinusPercent =
          Math.floor(biggestTopScore) - Math.floor(stat.myTeamTkl);
        let sign = '';
        if (plusMinusPercent >= 0) sign = '+';
        return stat.myTkl === biggestTopScore
          ? ['You Are', 'The Top Score', biggestTopScore + '%']
          : ['Top Score', biggestTopScore + '%'];
      }
      if (context.dataIndex === personalScorePosition) {
        const plusMinusPercent =
          Math.floor(value[1]) - Math.floor(stat.myTeamTkl);
        let sign = '';
        if (plusMinusPercent >= 0) sign = '+';
        return ['You', stat.myTkl + '%'];
      }
    };

    const bar = personalScoreBiggerOrEqual
      ? {
        type: 'bar' as any,
        label: 'Me',
        data: [
          null,
          null,
          null,
          null,
          [-100, 100],
          null,
          stat.myTkl === biggestTopScore ? null : [-100, 100],
          null,
          [-100, 100],
        ],
        backgroundColor: [
          '#000',
          '#000',
          '#000',
          '#000',
          'rgba(0,0,0,0.33)',
          '#000',
          '#62B772',
          '#000',
          '#4C649A',
        ],
        borderColor: [
          '#000',
          '#000',
          '#000',
          '#000',
          'rgba(0,0,0,0.33)',
          '#000',
          '#62B772',
          '#000',
          '#4C649A',
        ],
        datalabels: {
          font: datalabelsFont,
          formatter: getDataLabelsText,
          textAlign: 'center',
        },
      }
      : {
        type: 'bar' as any,
        label: 'Me',
        data: [
          null,
          null,
          [-100, 100],
          null,
          [-100, 100],
          null,
          null,
          null,
          [-100, 100],
        ],
        backgroundColor: [
          '#000',
          '#000',
          '#FF0000',
          '#000',
          'rgba(0,0,0,0.33)',
          '#000',
          '#000',
          '#000',
          '#4C649A',
        ],
        borderColor: [
          '#000',
          '#000',
          '#FF0000',
          '#000',
          'rgba(0,0,0,0.33)',
          '#000',
          '#000',
          '#000',
          '#4C649A',
        ],
        datalabels: {
          font: datalabelsFont,
          formatter: getDataLabelsText,
          textAlign: 'center',
        },
      };

    const data = {
      labels: ['', '', '', '', '', '', '', '', ''],
      datasets: [emptyLine, bar],
    };

    const personalScorePosition = personalScoreBiggerOrEqual ? 6 : 2;
    const bestScorePosition = personalScoreBiggerOrEqual ? 8 : 8;

    let comparisonString = (() => {
      const roundedScore = Math.floor(stat.myTkl);
      const roundedTeamScore = Math.floor(stat.myTeamTkl);
      switch (true) {
        case stat.myTkl === biggestTopScore: {
          return 'leading';
        }
        case roundedScore > roundedTeamScore: {
          return 'ahead of';
        }
        case roundedScore < roundedTeamScore: {
          return 'behind';
        }
        default:
          return 'right with';
      }
    })();
    let comparisonStringStyle;
    if (stat.myTkl === biggestTopScore)
      comparisonStringStyle = classes.colorBlue;
    else if (personalScoreBiggerOrEqual)
      comparisonStringStyle = classes.colorGreen;
    else comparisonStringStyle = classes.colorRed;

    return (
      <div className={classes.performance}>
        {/*<div className={classes.statisticBlock}>*/}
        {/*  {personalBlocks.map(({name, title, icon}) => (*/}
        {/*    <StatisticBlock*/}
        {/*      key={name}*/}
        {/*      name={name}*/}
        {/*      title={title}*/}
        {/*      icon={icon}*/}
        {/*      value={name === 'time' ? stat.avgSessionTime : stat.totalCards}*/}
        {/*    />*/}
        {/*  ))}*/}
        {/*</div>*/}
        <div className={classes.TKLBlock}>
          <RingBlock size={160} percent={stat.myTkl} strokeWidth={16} />
          <div className={classes.TKLText} onClick={openTKLPopup}>What is a TKL?</div>
        </div>
        {leaderBoardEnabled && (
          <div className={classes.teamTKLBlock}>
            <div className={classes.chartDetailsContainer}>
              <p className={classes.stateString}>
                You are currently{' '}
                <span className={comparisonStringStyle}>{comparisonString}</span>{' '}
                your teammates
              </p>
            </div>
            <div
              className={
                stat.myTkl === biggestTopScore
                  ? classes.chartContainerShort
                  : classes.chartContainer
              }>
              <Chart
                type="bar"
                options={options}
                data={data}
                height={250}
                width={330}
              />
            </div>
          </div>
          )}
      </div>
    );
  }
};

const personalBlocks = [
  {
    name: 'time',
    icon: Timer,
    title: `Average \nSession Time`,
  },
  {
    name: 'tasks',
    icon: TaskList,
    title: `Cards \nReviewed`,
  },
];

const StatisticBlock = ({
  name,
  title,
  icon,
  value,
}: {
  name: string;
  title: string;
  icon: string;
  value: number;
}) => {
  const classes = useStyles();

  const formatTime = (seconds: number) => {
    if (seconds < 60) {
      return `${Math.round(seconds)} sec`;
    }

    const minutes = seconds / 60;
    const secondsLeft = Math.floor(seconds % 60);

    return `${Math.floor(minutes)}${
      secondsLeft > 0 ? '.' + secondsLeft : ''
    } min`;
  };

  const getCards = (cards: number) => {
    return cards >= 10000 ? '9999+' : cards + ' cards';
  };

  return (
    <div className={classes.statisticContainer}>
      <div className={classes.iconContainer}>
        <img src={icon} alt={name} />
      </div>
      <div className={classes.textContainer}>
        <div className={classes.titleText}>{title}</div>
        <div className={classes.valueText}>
          {name === 'time' ? formatTime(value) : getCards(value)}
        </div>
      </div>
    </div>
  );
};

