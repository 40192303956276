import { passwordLengthRequirement } from "../pages/SignUp";

export const validateText = (value: string): string => {
  if (!value.trim()) {
    return 'This field is required';
  }
  return '';
};

export const validateEmail = (value: string): string => {
  if (!value) {
    return 'This field is required';
  }
  if (!/^([\p{L}\p{N}!#$%&'*+/=?^_`{|}~-]+(?:\.[\p{L}\p{N}!#$%&'*+/=?^_`{|}~-]+)*)@((?:[\p{L}\p{N}]+[\p{L}\p{N}][.-]?)+\p{L}{2,})$/u.test(value)) {
    return 'Invalid email address';
  }
  return '';
};

export const validateEmailConfirmation = (
  confirmEmail: string,
  email: string,
): string => {
  if (!confirmEmail) {
    return 'This field is required';
  }

  if (confirmEmail === email) {
    return '';
  }

  return 'Emails do not match';
};

export const validatePassword = (value: string): string => {
  if (!value) {
    return 'This field is required';
  }

  if (
    value.length >= passwordLengthRequirement &&
    /[a-z]/.test(value) &&
    /[A-Z]/.test(value) &&
    /\d/.test(value)
  ) {
    return '';
  }

  return `${passwordLengthRequirement} characters or longer.\nAt least one number or symbol.\nAt least one uppercase letter.`;
};

export const validatePasswordConfirmation = (
  value: string,
  password: string,
): string => {
  if (!value) {
    return 'This field is required';
  }

  if (value === password) {
    return '';
  }

  return 'Passwords do not match';
};
