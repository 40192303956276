import * as io from 'io-ts';

export const PartnerTODecoder = io.type({
  id: io.number,
  l: io.string,
  u: io.string,
  showTermOfUse: io.boolean,
  termOfUseUrl: io.union([io.null, io.string]),
  showFeedbackAlways: io.boolean,
  showMostKnowledgeableUsersPercentage: io.boolean,
  showFullUserKnowledgeList: io.boolean,
  showLeaderboard: io.boolean,
  enableLibrary: io.boolean,
  enableUserPerformance: io.boolean,
  enableExternalAnalytics: io.boolean,
  allowUsersChangeRemindDays: io.boolean,
  allowUsersChangeRemindTime: io.boolean,
});

export type PartnerTO = io.TypeOf<typeof PartnerTODecoder>;
