import * as TE from 'fp-ts/lib/TaskEither';
import * as Sentry from '@sentry/react';
import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from 'axios';
import * as O from 'fp-ts/lib/Option';
import {BSError, BSErrorType} from '../model/error/BSError';
import {pipe} from 'fp-ts/lib/function';
import {BSTask} from '../model/types';
import AppConfig from '../AppConfig';
import {debug} from '../util/logger';

let SrsAPI = axios.create({
  baseURL: AppConfig.apiUrl,
  timeout: AppConfig.requestTimeout,
  withCredentials: true,
});

if (AppConfig.debugHttp) {
  SrsAPI.interceptors.request.use((request: AxiosRequestConfig) => {
    debug('Starting Request', request);
    return request;
  });

  SrsAPI.interceptors.response.use((response: AxiosResponse) => {
    debug('Response:', response);

    return response;
  });
}

const plainApiErrorMessage = (err: unknown): O.Option<string> =>
  pipe(
    O.fromNullable(err as string),
    O.chain((str: string) =>
      (str + '').indexOf('<html>') > -1 ? O.none : O.some(str),
    ),
  );

export const UnknownError: BSError = {
  type: BSErrorType.UnexpectedError,
  userFriendlyTitle: 'Something went wrong',
  userFriendlyExplanation:
    'It’s not you it is up. Our system is down, but please check back soon.',
};

export const ServerDownError: BSError = {
  type: BSErrorType.ServiceUnavailableError,
  userFriendlyTitle: "It's not you. It's us.",
  userFriendlyExplanation:
    "Our server is down, but we're working on the issue. Please try again soon.",
};
export const ServerNotReachableError = {
  type: BSErrorType.ServiceUnavailableError,
  userFriendlyTitle: 'Server error',
  userFriendlyExplanation:
    'There was an error connecting to the server. Please try again soon.',
};

type ErrorResponse = {
  message: string;
  status: string;
  type: string;
};

const handleErrorResponse = (e: AxiosError<unknown>): BSError => {
  if (!e.response) {

    Sentry.captureMessage('handleErrorResponse !e.response',{
      level: 'error',
      extra: {
        e
      },
    });

    return UnknownError;
  }

  const status = e.response.status;
  const responseData = e.response.data as ErrorResponse;

  if (status === 403 || status === 404 || status === 500) {
    if (responseData.type === 'RealmIsNotFound') {
      return {
        type: BSErrorType.RealmIsNotFound,
        userFriendlyTitle: "",
        userFriendlyExplanation: ""
      }
    }

    return {
      type: BSErrorType.ServiceUnavailableError,
      userFriendlyTitle: 'Server error',
      userFriendlyExplanation: responseData.message,
    };
  } else if (status === 503) {
    return {
      type: BSErrorType.AccountError,
      userFriendlyTitle: 'Account blocked',
      userFriendlyExplanation:
        'Your account is blocked. Please contact your supervisor.',
    }
  } else if (status === 466) {
    return {
      type: BSErrorType.PasswordRequirementsNotMetError,
      userFriendlyTitle: 'Password requirements not met',
      userFriendlyExplanation: responseData.message,
    };
  } else if (status === 553) {
    return {
      type: BSErrorType.AccountError,
      userFriendlyTitle: 'Login failed',
      userFriendlyExplanation: 'Username or password is incorrect.',
    };
  } else if (status === 409) {
    const errorText = responseData.message;
    let userFriendlyExplanation = errorText;
    if (errorText.startsWith('User already exists')) userFriendlyExplanation = 'Your email address is already associated with an account, please click Back to Sign in and sign in there'
    if (errorText.search(/code.{0,}has expired/) > -1) userFriendlyExplanation = 'The invite code has expired, please ask your supervisor to send you a new one'
    return {
      type: BSErrorType.ConflictError,
      userFriendlyTitle: 'Conflict',
      userFriendlyExplanation,
    };
  } else {
    Sentry.captureMessage('handleErrorResponse unhandled status code',{
      level: 'error',
      extra: {
        e
      },
    });

    return UnknownError;
  }
};

const handleAxiosError = (e: AxiosError<unknown>): BSError => {
  if (e.response) {
    if (e.response.status === 405 && e.response.config?.baseURL?.startsWith('https://app-blankslate') && e.response.request?.responseURL?.includes('login.afwerx.dso.mil')) {
      window.location.reload();
    } else {
      return handleErrorResponse(e);
    }
  } else if (e.request) {
    return navigator.onLine ? ServerDownError : ServerNotReachableError;
  }

  Sentry.captureMessage('handleAxiosError unknown error',{
    level: 'error',
    extra: {
      e
    },
  });

  return UnknownError;
};

const handleHttpError = (e: unknown): BSError => pipe(
    O.fromNullable(e as AxiosError<unknown>),
    O.map(handleAxiosError),
    O.getOrElse<BSError>(() => UnknownError),
  );

const post = (
  url: string,
  data?: any,
  config?: AxiosRequestConfig,
): BSTask<AxiosResponse<unknown>> =>
  TE.tryCatch(() => {
    debug(
      `Sending POST request to: ${url} headers=${JSON.stringify(
        config?.headers,
      )}`,
    );
    return SrsAPI.post(url, data, config);
  }, handleHttpError);

const put = (
  url: string,
  data?: any,
  config?: AxiosRequestConfig,
): BSTask<AxiosResponse<unknown>> =>
  TE.tryCatch(() => {
    debug(
      `Sending PUT request to: ${url} headers=${JSON.stringify(
        config?.headers,
      )}`,
    );

    return SrsAPI.put(url, data, config);
  }, handleHttpError);

const get = (
  url: string,
  config?: AxiosRequestConfig,
): BSTask<AxiosResponse<unknown>> =>
  TE.tryCatch(() => {
    debug(
      `Sending GET request to: ${url} headers=${JSON.stringify(
        config?.headers,
      )}`,
    );
    //return SrsAPI.get(url, {...config, withCredentials: true});
    return SrsAPI.get(url, config);
  }, handleHttpError);

const sendDelete = (
  url: string,
  config?: AxiosRequestConfig,
): BSTask<AxiosResponse<unknown>> =>
  TE.tryCatch(() => {
    debug(
      `Sending DELETE request to: ${url} headers=${JSON.stringify(
        config?.headers,
      )}`,
    );
    return SrsAPI.delete(url, config);
  }, handleHttpError);

export default {
  post,
  put,
  get,
  sendDelete,
};
