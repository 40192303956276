import React, {ReactNode, useCallback, useEffect, useMemo, useState} from 'react';
import styles from '../styles/pages/Tour.module.css';
import {useNavigate} from 'react-router-dom';
import LibraryTourCards from '../assets/images/LibraryTourCards.svg'
import LibraryTourCard from '../assets/images/LibraryTourCard.svg'
import LibraryTourTKL from '../assets/images/LibraryTourTKL.svg'
import makeStyles from "@mui/styles/makeStyles";
import {Button} from "@mui/material";
import {BSTheme} from "../AppTheme";
import clsx from "clsx";
import {useRecoilState} from "recoil";
import UserService from "../service/UserService";
import {debug} from "../util/logger";
import {pipe} from "fp-ts/function";
import * as O from "fp-ts/Option";
import {User} from "../model/domain/User";
import * as TE from "fp-ts/TaskEither";
import {executeTask} from "../util/bs-fp";
import {userState} from "../model/state/userState";

const useStyles = makeStyles((theme: BSTheme) => ({
  root: {
    display: 'flex',
    height: '10px',
    backgroundColor: theme.palette.extended.progressBarBackground,
    alignSelf: 'stretch',
    borderRadius: '12px',
  },
  progress: {
    backgroundColor: theme.palette.extended.libraryTourProgress,
  },
  finishProgress: {
    borderRadius: '12px',
  },
  roundedProgress: {
    borderTopLeftRadius: '12px',
    borderBottomLeftRadius: '12px',
  },
  footer: {
    width: '200px',
    marginBottom: '31px',
  },
  image1: {
    height: '234px',
    marginTop: '50px',
    marginBottom: '60px',
  },
  image2: {
    height: '292px',
    marginTop: '40px',
    marginBottom: '40px',
  },
  image3: {
    height: '242px',
    marginTop: '40px',
    marginBottom: '50px'
  },
  button: {
    width: '165px',
    height: '42px',
    borderRadius: '100px',
    marginBottom: '30px',
  },
  button_text: {
    fontWeight: 500,
    fontSize: '20px',
    color: theme.palette.secondary.main,
  },
  message_box: {
    display: 'flex',
    flex: 0,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontFamily: 'Barlow Semi Condensed',
    fontWeight: 700,
    fontSize: '26px',
    lineHeight: '26px',
    paddingBottom: '0.3em',
  },
  subTitle: {
    fontFamily: 'Roboto Condensed',
    fontWeight: 400,
    fontSize: '20px',
    color: theme.palette.primary.main,
  },
}));

interface LibraryTourProps {}

const LibraryTour: React.FC<LibraryTourProps> = ({}) => {
  const classes = useStyles();
  const messages = useMemo<LibraryTourMessageModel[]>(() => {
    return [
      {
        title: ['Welcome to the Library'],
        subtitle: [
          <div>Here you can review all of your cards after</div>,
          <div>you finish each Blank Slate session.</div>
        ],
        image: <img className={classes.image1} alt="Blank Slate" src={LibraryTourCards} />
      },
      {
        title: [],
        subtitle: [
          <div>Cards in the Library have the correct answers</div>,
          <div>highlighted, like the example below. You can simply</div>,
          <div>click “Next” to flip through your cards.</div>

        ],
        image: <img className={classes.image2} alt="Blank Slate" src={LibraryTourCard} />
      },
      {
        title: [],
        subtitle: [
          <div>One last thing! Anything you do in the Library will</div>,
          <div><b>not</b> affect your True Knowledge Level.</div>
        ],
        image: <img className={classes.image3} alt="Blank Slate" src={LibraryTourTKL} />
      },
    ];
  }, [classes.image1, classes.image2, classes.image3]);

  const navigate = useNavigate();
  const totalSteps = useMemo(() => messages.length, [messages]);
  const [step, setStep] = useState(1);
  const isFinalStep = useMemo(() => totalSteps === step, [totalSteps, step]);
  const [user, setUser] = useRecoilState(userState);

  const MessageContent = useCallback(() => {
    if (step < 1 || step > messages.length) {
      return null;
    }
    const message = messages[step - 1];
    return <LibraryTourMessageBox title={message.title} subtitle={message.subtitle} image={message.image} />;
  }, [step, messages]);

  const collectUser = (): O.Option<User> =>
    pipe(
      user,
      O.map(
        (u: User) =>
          ({
            ...u,
            libraryTourShown: true,
          } as User),
      ),
    );

  const goToLibrary = useCallback(async (): Promise<void> => {
    await pipe(
      collectUser(),
      O.fold(
        () => Promise.resolve(),
        (u: User) => {
          return setLibraryTourShown(u);
        },
      ),
    );
  },
    // eslint-disable-next-line
  []);

  const setLibraryTourShown = (u: User): Promise<void> => {
    return executeTask(pipe(
      UserService.setLibraryTourShown(),
      TE.chainFirst(() =>
        TE.fromIO(() => {
          debug('Set User', u);
          setUser(O.some(u));
        }),
      ),
    ));
  };


  const onNextStep = useCallback(() => {
    if (isFinalStep) {
      goToLibrary().finally( () => navigate('/library'));
    } else {
      setStep(step + 1);
    }
  }, [isFinalStep, step, goToLibrary, navigate]);

  useEffect(() => {
    document.title = 'Library Tour – Blank Slate';
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div className={styles.messageContent}>
          <MessageContent />
        </div>
        <div className={classes.footer}>
          <LineProgressBar steps={totalSteps} current={step} />
        </div>
        <div className={styles.buttonsContainer}>
          <NextButton
            text={isFinalStep ? 'Get Started!' : 'Next'}
            onClick={onNextStep}
          />
        </div>
      </div>

    </div>
  );
};

interface LineProgressBarProps {
  steps: number;
  current: number;
}

const LineProgressBar: React.FC<LineProgressBarProps> = ({steps, current}) => {
  const classes = useStyles();
  const fullProgress = useMemo(() => current >= steps, [current, steps]);
  const progressWidth = useMemo(
    () =>
      steps == 0
        ? '0%'
        : fullProgress
          ? '100%'
          : `${(current * 100.0) / steps}%`,
    [fullProgress, steps, current],
  );

  return (
    <div className={classes.root}>
      <span
        className={clsx(
          classes.progress,
          fullProgress ? classes.finishProgress : classes.roundedProgress,
        )}
        style={{width: progressWidth}}
      />
    </div>
  );
};

interface NextButtonProps {
  text: string;
  onClick: () => void;
}

const NextButton: React.FC<NextButtonProps> = ({
   text,
   onClick,
}) => {
  const classes = useStyles();
  return (
    <Button
      style={{backgroundColor: '#ADADAD'}}
      variant="contained"
      className={classes.button}
      onClick={onClick}
    >
      <span className={classes.button_text}>{text}</span>
    </Button>
  );
};

interface LibraryTourMessageModel {
  title: string[];
  subtitle: ReactNode[];
  image: object
}

interface LibraryTourMessageBoxProps extends LibraryTourMessageModel {}

const LibraryTourMessageBox: React.FC<LibraryTourMessageBoxProps> = ({title, subtitle, image}) => {
  const classes = useStyles();
  const titleElements = title.map((t, idx) => (
    <div key={`t${idx}`} className={classes.title}>
      {t}
    </div>
  ));
  const subtitleElements = subtitle.map((t, idx) => (
    <div key={`st${idx}`} className={classes.subTitle}>
      {t}
    </div>
  ));
  return (
    <div className={classes.message_box}>
      <>
        {titleElements}
        {subtitleElements}
        {image}
      </>
    </div>
  );
};

export default LibraryTour;
