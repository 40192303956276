import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import SignIn from './SignIn';
import WelcomeBack from './WelcomeBack';
import clsx from 'clsx';
import styles from '../styles/pages/Layout.module.css';
import Tour from './Tour';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {userState} from '../model/state/userState';
import * as O from 'fp-ts/Option';
import {User} from '../model/domain/User';
import {pipe} from 'fp-ts/lib/function';
import AuthService from '../service/AuthService';
import * as T from 'fp-ts/Task';
import {debug} from '../util/logger';
import * as E from 'fp-ts/Either';
import Study from './Study';
import AssessmentFinishPage from './AssessmentFinishPage';
import SignUp from './SignUp';
import PasswordReset from './PasswordReset';
import ChangePassword from './ChangePassword';
import UpdatePassword from './UpdatePassword';
import Feedback from './Feedback';
import Settings from './Settings';
import PartnerLogo from '../components/PartnerLogo';
import {agreementState} from '../model/state/agreementState';
import {PrivacyPolicy} from './PrivacyPolicy';
import {LogoutPage} from './Logout';
import {PasswordPreferencesPage} from './SubMenuPage';
import PerformancePage from './PerformancePage';
import { DeckList} from './LibraryPage';
import { DeckDetails } from './DeckDetails';
import {ExternalResourcesList} from './ExternalResourcesList';
import {BSTask} from '../model/types';
import {ErrorInterrupter} from '../components/ErrorInterrupter';
import {LoadingFallback} from '../components/progress/LoadingFallback';
import {sideMenuForceOpen} from '../model/state/sideMenuState';
import {nextStepState} from '../model/state/nextStepState';
import Analytics from '../components/Analytics';
import LibraryTour from './LibraryTour';

interface LayoutProps {}

const Layout: React.FC<LayoutProps> = ({}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [user, setUser] = useRecoilState(userState);
  const [isLoading, setLoading] = useState(true);
  const isLoggedIn = useMemo(() => O.isSome(user), [user]);
  const setAgreement = useSetRecoilState(agreementState);

  const signInWithTokenTask = useCallback<() => BSTask<User>>(
    () => pipe(AuthService.signInWithToken()),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onSignInError = useCallback(() => {
    if (location.pathname !== '/privacy-policy') {
      navigate('/');
    }
    setUser(O.none);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  const onSignInOk = useCallback(
    (u: User): void => {
      setUser(O.some(u));
      if (u.partner.showTermOfUse) {
        setAgreement(u.partner.termOfUseUrl);
      }
      if (location.pathname !== '/privacy-policy') navigate('/welcomeBack');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setUser, navigate],
  );

  const signInOnStartup = useCallback(async () => {
    debug(`Signing in on startup`);
    return pipe(
      signInWithTokenTask(),
      T.map(E.fold(onSignInError, onSignInOk)),
      (invokeTask: T.Task<void>) => invokeTask(),
    );
  }, [signInWithTokenTask, onSignInError, onSignInOk]);

  const shouldSignInOnStartup = () => {
    debug('location.pathname=', location.pathname);
    let res = false;
    const doNotSignInOnPaths = [
      '/pws',
      '/signup',
      '/resetPws',
      '/privacy-policy',
    ];
    doNotSignInOnPaths.forEach(
      (path) => (res = res || location.pathname.startsWith(path)),
    );
    debug('res: ', res, location);
    return !res;
  };

  useEffect(() => {
    (async function foo() {
      setLoading(true);
      if (shouldSignInOnStartup()) {
        await signInOnStartup();
      }
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const enableUserPerformance = pipe(
    user,
    O.fold(
      () => false,
      (u) => u.partner.enableUserPerformance,
    ),
  );

  const enableLibrary = pipe(
    user,
    O.fold(
      () => false,
      (u) => u.partner.enableLibrary,
    ),
  );

  const nextStep = useRecoilValue(nextStepState);
  const [forceShowMenu, setForceShowMenu] = useRecoilState(sideMenuForceOpen);
  const isAssessmentCompleted =
    O.toNullable(nextStep)?.mode === 'Review' || O.isNone(O.toNullable(nextStep)?.c || O.some(false)) || O.toNullable(nextStep)?.mode === 'Survey';

  useEffect(() => {
    document.title = 'Blank Slate';
  }, []);

  const appRoutes = useMemo(() => {
    return (
      <>
        <Routes>
          <Route path={'/'} element={<SignIn />} />
          <Route path={'/welcomeBack'} element={<WelcomeBack />} />
          <Route path={'/study'} element={<Study />} />
          <Route path={'/updatePws'} element={<UpdatePassword />} />
          <Route path={'/privacy-policy'} element={<PrivacyPolicy />} />
          <Route path={'/tour'} element={<Tour />} />
          <Route path={'/feedback'} element={<Feedback />} />
          <Route path={'/congratulation'} element={<AssessmentFinishPage />} />
          <Route path={'/preferences'} element={<Settings />} />
          <Route path={'/settings'} element={<PasswordPreferencesPage />} />
          {enableUserPerformance && isAssessmentCompleted && (
            <Route path={'/performance'} element={<PerformancePage />} />
          )}
          {enableLibrary && isAssessmentCompleted ? (
            <Route path={'/library_tour'} element={<LibraryTour />} />
          ) : null}
          {enableLibrary && isAssessmentCompleted && (
            <Route path={'/library'}>
              <Route path={'/library/deck/:deckId'} element={<DeckDetails />} />
              <Route
                path={'/library/resources'}
                element={<ExternalResourcesList />}
              />
              <Route path={'/library'} element={<DeckList />} />
            </Route>
          )}
          <Route path={'/logout'} element={<LogoutPage />} />
          <Route path={'*'} element={<Navigate replace to="/welcomeBack" />} />
        </Routes>
      </>
    );
  }, [isAssessmentCompleted, enableUserPerformance, enableLibrary]);

  const authRoutes = useMemo(
    () => (
      <div>
        <Routes>
          <Route path={'/'} element={<SignIn />} />
          <Route path={'/signin'} element={<SignIn />} />
          <Route path={'/signup'} element={<SignUp />} />
          <Route path={'/resetPws'} element={<PasswordReset />} />
          <Route path={'/privacy-policy'} element={<PrivacyPolicy />} />
          <Route path={'/pws'} element={<ChangePassword />} />
        </Routes>
      </div>
    ),
    [],
  );

  const partnerLogo = pipe(
    user,
    O.fold(
      () => <span />,
      (u: User) => <PartnerLogo partner={u.partner} />,
    ),
  );

  return (
    <div className={clsx(styles.wrapper)}>
      {isLoading ? (
        <LoadingFallback />
      ) : (
        <>
          {isLoggedIn && (
            <div className={styles.header}>
              <Analytics />
              {forceShowMenu}
              <div />
              <div className={styles.headerRightCorner}>{partnerLogo}</div>
            </div>
          )}
          <ErrorInterrupter>
            <div className={styles.content}>
              {isLoggedIn ? appRoutes : authRoutes}
            </div>
          </ErrorInterrupter>
        </>
      )}
    </div>
  );
};

export default Layout;
