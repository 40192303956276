import React, {CSSProperties} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {BSTheme} from '../AppTheme';
import BSLogoImage from '../assets/images/bslogo.svg';
import clsx from 'clsx';

const useStyles = makeStyles((theme: BSTheme) => ({
  root: {
    fontFamily: 'orpheus-pro',
    fontWeight: 400,
    display: 'flex',
    justifyContent: 'center',
  },
  primary: {
    color: theme.palette.primary.main,
  },
  secondary: {
    color: theme.palette.secondary.main,
  },
  logoLink: {
    textDecoration: 'inherit',
    color: 'inherit',
    display: 'flex',
  },
}));

export interface BSLogoProps {
  palette: 'primary' | 'secondary';
  style?: CSSProperties;
}

const BSLogo: React.FC<BSLogoProps> = ({palette, style}) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, classes[palette])} {...{style}}>
      <a
        className={classes.logoLink}
        href="https://www.blankslatetech.co/"
        target="_blank">
        <img alt="" src={BSLogoImage} />
      </a>
    </div>
  );
};

export default BSLogo;
