import React, {useCallback, useEffect, useState} from 'react';
import styles from '../styles/pages/SignIn.module.css';
import makeStyles from '@mui/styles/makeStyles';
import {BSTheme} from '../AppTheme';
import clsx from 'clsx';
import AuthInput from '../components/input/AuthInput';
import {Link as RouterLink} from 'react-router-dom';
import Link from '@mui/material/Link';
import AuthButton from '../components/buttons/AuthButton';
import {debug} from '../util/logger';
import {pipe} from 'fp-ts/lib/function';
import * as T from 'fp-ts/Task';
import * as TE from 'fp-ts/TaskEither';
import * as O from 'fp-ts/Option';
import * as E from 'fp-ts/Either';
import {User} from '../model/domain/User';
import AuthService from '../service/AuthService';
import {useSetRecoilState} from 'recoil';
import {userState} from '../model/state/userState';
import {useNavigate} from 'react-router-dom';
import BSLogo from '../components/BSLogo';
import {agreementState} from '../model/state/agreementState';
import {BSTask} from '../model/types';
import {useError} from '../model/state/errorState';
import {BSError, BSErrorType} from '../model/error/BSError';
import {FieldWithValidation} from "../components/input/FieldWithValidation";
import {validateEmail} from "../vallidation/validation";
import AppConfig from "../AppConfig";

const useStyles = makeStyles((theme: BSTheme) => ({
  root: {
    paddingTop: '50px',
    backgroundColor: theme.palette.extended.authBackgroundColor,
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
}));

const initErrorState = {
  email: '',
  password: '',
};

interface SignInProps {}

const SignIn: React.FC<SignInProps> = ({}) => {
  const themeStyles = useStyles();
  let navigate = useNavigate();

  const setUser = useSetRecoilState(userState);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [inProgress, setInProgress] = useState(false);
  const [errorMessage, setErrorMessage] = useState(initErrorState);
  const setAgreement = useSetRecoilState(agreementState);
  const [authWay, setAuthWay] = useState<string | null>(null);

  const beforeLogin = useCallback(() => {
    setInProgress(true);
    setErrorMessage(initErrorState);
  }, []);

  const afterLogin = useCallback(() => setInProgress(false), []);

  const setToast = useError(true);

  const signInTask = (email: string, password: string): BSTask<User> =>
    pipe(
      TE.of(beforeLogin()),
      () => AuthService.signInWithCredentials(email, password),
      TE.mapLeft(setToast),
      T.chainFirst(() => TE.of(afterLogin())),
    );

  const onSignInError = useCallback(
    (e: BSError): void => {
      setUser(O.none);
    },
    [setUser],
  );

  const onSignInOk = useCallback(
    (u: User): void => {
      setUser(O.some(u));
      if (u.partner.showTermOfUse) {
        setAgreement(u.partner.termOfUseUrl);
      }
      navigate('/welcomeBack');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setUser, navigate],
  );

  const onSignInKeycloak = () => {
    const error = validateEmail(email);
    if (error) {
      setErrorMessage({ ...errorMessage, email: error });
      return;
    }

    setErrorMessage(initErrorState);

    setInProgress(true);

    window.location.href = `${AppConfig.apiUrl}/oauth2?email=${email}&destination=${AppConfig.redirectUrl}`;
    setInProgress(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSignIn = async () => {
    const errors = {
      email: validateEmail(email),
      password: password.length === 0 ? 'Password is required' : '',
    };

    if (errors.email || errors.password) {
      setErrorMessage(errors);
      return;
    }

    setInProgress(true);

    await pipe(
      signInTask(email, password),
      T.map(E.fold(onSignInError, onSignInOk)),
      (invokeTask: T.Task<void>) => invokeTask(),
    );

    setInProgress(false);
  };

  const onNextHandler = async() => {
    const emailError = validateEmail(email);

    if (emailError) {
      setErrorMessage({email: emailError, password: ''});
      return;
    }

    setErrorMessage({ ...errorMessage, email: '' });

    setInProgress(true);

    await pipe(
      AuthService.checkAuthWay(email),
      T.chain(E.fold(
        (err) => {
          console.log('error', BSErrorType.RealmIsNotFound);
          if (err.type === BSErrorType.RealmIsNotFound) {
            setAuthWay('email');
          } else {
            setToast(err);
          }
          return T.of(undefined);
        },
        () => {
          setAuthWay('keycloak');
          return T.of(undefined);
        })),
    )();

    setInProgress(false);
  };

  useEffect(() => {
    const f = onSignIn;
    const listener = (event: KeyboardEvent) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        debug('Enter key was pressed. Run your function.');
        event.preventDefault();
        f();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [onSignIn]);

  useEffect(() => {
    document.title = 'Sign In – Blank Slate';
  }, []);

  // if (!isLoading) {
  //   return <div className={themeStyles.loadingContainer}><LoadingFallback /></div>;
  // }

  return (
    <div className={clsx(styles.root, themeStyles.root)}>
      <div className={styles.logoContainer}>
        <BSLogo palette={'secondary'} style={{fontSize: '72px'}} />
      </div>
      <div className={styles.authInputContainer}>
        <FieldWithValidation
          errorMessage={errorMessage['email']}
        >
          <AuthInput
            key={'email'}
            placeholder={'Enter your email'}
            secure={false}
            value={email}
            onChange={(value) => {
              setEmail(value);
              setAuthWay(null);
            }}
          />
        </FieldWithValidation>
        {authWay === 'email' && (
          <div>
            <div className={styles.passwordInput}>
              <FieldWithValidation
                errorMessage={errorMessage['password']}
              >
                <AuthInput
                  key={'password'}
                  placeholder={'Enter your password'}
                  secure={true}
                  value={password}
                  onChange={setPassword}
                />
              </FieldWithValidation>
            </div>
            <div className={styles.authLinksContainer}>
              <RouterLink to={'/resetPws'}>
                <Link
                  className={styles.authLink}
                  component="button"
                  color={'secondary'}
                  tabIndex={-1}>
                  Forgot your password?
                </Link>
              </RouterLink>
            </div>
          </div>
        )}
      </div>
      <div className={styles.signInButtonContainer}>
        {authWay ? (
          <AuthButton
            text={'Sign In'}
            onClick={authWay === 'email' ? onSignIn : onSignInKeycloak}
            signInButton={true}
            disabled={inProgress || email.length == 0 || (authWay === 'email' && password.length == 0)}
          />
        ) : (
          <AuthButton
            text={'Next'}
            onClick={onNextHandler}
            disabled={inProgress || email.length == 0}
          />
        )}
        {/*<div className={styles.signUpContainer}>
             <RouterLink to={'/signUp'}>
              <Link
                className={styles.signUpLink}
                component="button"
                color={'secondary'}
                tabIndex={-1}>
                Create an account
              </Link>
            </RouterLink>
          </div> */}
      </div>
    </div>
  );
};

export default SignIn;
