import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styles from '../../styles/pages/Study.module.css';
import {CardHtml} from '../../model/domain/CardHtml';
import CardFront from '../card/CardFront';
import {pipe} from 'fp-ts/lib/function';
import * as O from 'fp-ts/Option';
import CardBack from '../card/CardBack';
import CardService from '../../service/CardService';
import StudyService from '../../service/StudyService';
import {useRecoilValue} from 'recoil';
import LineProgressBar from '../progress/LineProgressBar';
import CardFeedback from '../card/CardFeedback';
import {userState} from '../../model/state/userState';
import {useAsyncState} from '../../model/state/asyncState';
import {ErrorPage} from '../ErrorInterrupter';
import {LoadingFallback} from '../progress/LoadingFallback';
import clsx from 'clsx';

interface LibraryStudyProps {
  cardIds: string[];
  deckState: any;
  onClose: () => void;
}

const LibraryStudy: React.FC<LibraryStudyProps> = ({
  cardIds,
  deckState,
  onClose,
}) => {
  const [currentCardIndex, setCurrentCardIndex] = useState(0);

  const getState = useCallback(() => {
    return StudyService.getUserLibraryCard(cardIds[currentCardIndex]);
  }, [cardIds, currentCardIndex]);

  const state = useAsyncState(getState);

  useEffect(() => {
    document.title = 'Library Card – Blank Slate';
  }, []);

  if (state.error) return <ErrorPage error={state.error} onReset={() => {}} />;

  return (
    <>
      {state.inProgress || !state.data ? (
        <LoadingFallback />
      ) : (
        <Study
          card={state.data}
          cardIds={cardIds}
          currentCardIndex={currentCardIndex}
          setCurrentCardIndex={setCurrentCardIndex}
          onClose={onClose}
        />
      )}
    </>
  );
};

interface StudyProps {
  card: CardHtml;
  cardIds: string[];
  currentCardIndex: number;
  setCurrentCardIndex: (index: number) => void;
  onClose: () => void;
}

const Study: React.FC<StudyProps> = ({
  card,
  cardIds,
  currentCardIndex,
  setCurrentCardIndex,
  onClose,
}) => {
  const [isFront, setIsFront] = useState(true);
  const [rawAnswers, setRawAnswers] = useState<O.Option<string[]>>(O.none);
  const user = useRecoilValue(userState);
  const [cardBackWasShown, setCardBackWasShown] = useState(false);

  const feedbackText = useMemo(
    () =>
      pipe(
        O.fromNullable(card),
        O.chain((c: CardHtml) => c.bComment),
        O.getOrElse(() => ''),
      ),
    [card],
  );

  const checkAnswerIsCorrect = useCallback(
    (card: CardHtml, rawAnswers: O.Option<string[]>) =>
      CardService.checkAnswerCorrectness(card, rawAnswers),
    [],
  );

  const onFrontAnswer = useCallback(
    (answers: string[]) => {
      setRawAnswers(O.some(answers));
      setIsFront(false);
    },
    [setRawAnswers, setIsFront],
  );

  const answerIsCorrect = useMemo(
    () => checkAnswerIsCorrect(card, rawAnswers),
    [checkAnswerIsCorrect, card, rawAnswers],
  );

  const hasFeedback = useMemo(() => {
    let isCorrect = O.getOrElse(() => false)(answerIsCorrect);
    return (
      feedbackText.length > 0 &&
      (!isCorrect || O.toNullable(user)?.partner.showFeedbackAlways)
    );
  }, [feedbackText, answerIsCorrect, user]);

  const onBackAnswer = useCallback(() => {
    if (cardIds.length === currentCardIndex + 1) {
      onClose();
    } else if (!hasFeedback) {
      setCurrentCardIndex(currentCardIndex + 1);
    } else {
      setCardBackWasShown(true);
    }

    setRawAnswers(O.none);
  }, [currentCardIndex, cardIds, hasFeedback, onClose, setCurrentCardIndex]);

  const onFeedbackAnswer = useCallback(() => {
    setCardBackWasShown(false);
    // setRawAnswers(O.none);
    setIsFront(true);
    setCurrentCardIndex(currentCardIndex + 1);
  }, [currentCardIndex, setCurrentCardIndex]);

  const frontContent = useMemo(
    () => (
      <CardFront
        isLoading={false}
        cardCounter={currentCardIndex}
        card={card}
        onAnswer={onFrontAnswer}
      />
    ),
    [card, currentCardIndex, onFrontAnswer],
  );

  const backContent = useMemo(
    () => (
      <CardBack
        isCorrect={checkAnswerIsCorrect(card, rawAnswers)}
        isAnswerTimeExceeded={false}
        card={card}
        onAnswer={onBackAnswer}
        nextQuestionButtonText={
          currentCardIndex === cardIds.length - 1 ? 'Back to deck' : 'Next'
        }
        studyMode="library"
      />
    ),
    [
      card,
      cardIds.length,
      checkAnswerIsCorrect,
      currentCardIndex,
      onBackAnswer,
      rawAnswers,
    ],
  );

  const feedbackContent = useMemo(
    () => <CardFeedback text={feedbackText} onNext={onFeedbackAnswer} />,
    [feedbackText, onFeedbackAnswer],
  );

  return (
    <>
      <div className={clsx(styles.content, styles.content_white)}>
        {isFront
          ? frontContent
          : hasFeedback && cardBackWasShown
          ? feedbackContent
          : backContent}
      </div>
      <div className={styles.footer}>
        <LineProgressBar
          steps={cardIds.length}
          current={currentCardIndex + 1}
        />
      </div>
    </>
  );
};

export default LibraryStudy;
