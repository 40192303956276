import {UserTO, userTODecoder} from './to/UserTO';
import RestService from './RestService';
import {pipe} from 'fp-ts/lib/function';
import {BSTask} from '../model/types';
import * as TE from 'fp-ts/lib/TaskEither';
import * as O from 'fp-ts/lib/Option';
import {AxiosResponse} from 'axios';
import {AuthUser} from '../model/domain/AuthUser';
import AuthUserConverter from './converter/AuthUserConverter';
import {BSTaskVoid} from '../util/bs-fp';
import AppConfig from "../AppConfig";

const buildSignInRequestPayload = (
  emailWithPassword: O.Option<[string, string]>,
): undefined | string => {
  return pipe(
    emailWithPassword,
    O.map(([email, password]) => {
      const encodedEmail = encodeURIComponent(email);
      const encodedPassword = encodeURIComponent(password);
      return `email=${encodedEmail}&pws=${encodedPassword}`;
    }),
    O.toUndefined,
  );
};

const parseUserAndToken = (resp: AxiosResponse<unknown>) =>
  pipe(
    userTODecoder(TE.of(resp)),
    TE.map((u: UserTO) => AuthUserConverter.toDomain(u)),
  );

const signIn = (
  emailWithPassword: O.Option<[string, string]>,
): BSTask<AuthUser> => {
  const url = '/auth';
  const data = buildSignInRequestPayload(emailWithPassword);
  return pipe(RestService.post(url, data), TE.chain(parseUserAndToken));
};

const checkAuthWay = (email: string) => {
  const url = '/oauth2';
  return pipe(
    RestService.put(url, undefined, {params: { email, destination: AppConfig.redirectUrl }}),
    TE.map((resp) => {
      return resp.data;
    }),
  )
};

const resetPassword = (email: string): BSTask<void> => {
  const url = '/auth/pws/reset';
  const data = 'email=' + encodeURIComponent(email);
  return pipe(
    RestService.post(url, data),
    TE.chain(() => BSTaskVoid),
  );
};

const signUp = (
  email: string,
  password: string,
  username: string,
  appUuid: string,
  inviteCode?: string,
): BSTask<AuthUser> => {
  const url = '/auth/signup/app';
  const data =
    `uuid=${encodeURIComponent(appUuid)}&email=${encodeURIComponent(email)}&pws=${encodeURIComponent(password)}&username=${encodeURIComponent(username)}${inviteCode ? '&invite_code=' + encodeURIComponent(inviteCode) : ''}`;

  return pipe(RestService.put(url, data), TE.chain(parseUserAndToken));
};

const signUpForInvited = (
  email: string,
  password: string,
  username: string,
  inviteCode?: string,
): BSTask<AuthUser> => {
  const url = '/auth';
  const data =
    `email=${encodeURIComponent(email)}&pws=${encodeURIComponent(password)}&username=${encodeURIComponent(username)}${inviteCode ? '&invite_code=' + encodeURIComponent(inviteCode) : ''}`;

  return pipe(RestService.put(url, data), TE.chain(parseUserAndToken));
};

const signOut = (): BSTask<void> => {
  const url = '/auth';
  return pipe(
    RestService.sendDelete(url),
    TE.chain(() => BSTaskVoid),
  );
};

const acceptAgreement = (): BSTask<void> => {
  const url = '/app/user/tos/accept';
  return pipe(
    RestService.post(url),
    TE.chain(() => BSTaskVoid),
  );
};

const updatePassword = (secret: string, password: string): BSTask<void> => {
  const url = '/auth/pws/upd';
  const data =
    'secret=' +
    encodeURIComponent(secret) +
    '&pws=' +
    encodeURIComponent(password);
  return pipe(
    RestService.post(url, data),
    TE.chain(() => BSTaskVoid),
  );
};

const updPasswordWithCurrent = (
  email: string,
  newPassword: string,
  currentPassword: string,
): BSTask<void> => {
  const url = '/auth/pws/upd';
  const data =
    'email=' +
    encodeURIComponent(email) +
    '&newPws=' +
    encodeURIComponent(newPassword) +
    '&pws=' +
    encodeURIComponent(currentPassword);
  return pipe(
    RestService.put(url, data),
    TE.chain(() => BSTaskVoid),
  );
};

export default {
  signIn,
  resetPassword,
  checkAuthWay,
  signUp,
  signUpForInvited,
  signOut,
  updatePassword,
  updPasswordWithCurrent,
  acceptAgreement,
};
