import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styles from '../styles/pages/Congratulation.module.css';
import {Theme} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {userState} from '../model/state/userState';
import {useNavigate} from 'react-router-dom';
import {pipe} from 'fp-ts/lib/function';
import * as O from 'fp-ts/Option';
import * as T from 'fp-ts/Task';
import * as TE from 'fp-ts/TaskEither';
import * as E from 'fp-ts/Either';
import {User} from '../model/domain/User';
import {
  nextCardState,
  nextStudyProgressState,
} from '../model/state/nextStepState';
import {AnimationTypeEnum} from '../model/domain/enum/AnimationTypeEnum';
import BSAnimation from '../components/BSAnimation';
import {useWindowSize} from '../util/hooks/useWindowSize';
import NextButton from '../components/buttons/NextButton';
import AuthService from '../service/AuthService';
import {executeTask} from '../util/bs-fp';
import {useShortcuts} from '../hooks/useShortcuts';
import PerformancePage from './PerformancePage';
import {useError} from '../model/state/errorState';
import {NextStepProgressWithReview} from '../model/domain/NextStep';
import StatService from "../service/StatService";

const useStyles = makeStyles((theme: Theme) => ({
  viewYouJustBurned: {
    fontFamily: 'Roboto Condensed',
    fontWeight: 700,
    fontSize: '32px',
    color: theme.palette.primary.main,
    marginTop: '60px',
  },
  viewNiceWork: {
    fontFamily: 'Barlow Semi Condensed',
    fontWeight: 700,
    fontSize: '48px',
    textTransform: 'uppercase',
  },
  viewCorrectnessMark: {
    paddingTop: '22px',
    paddingBottom: '22px',
  },
  viewMessage: {
    fontFamily: 'Roboto Condensed',
    fontWeight: 400,
    fontSize: '27px',
    color: theme.palette.primary.main,
    textAlign: 'center',
    marginTop: '25px',
  },
  viewSeeYou: {
    fontFamily: 'Barlow Semi Condensed',
    fontWeight: 700,
    fontStyle: 'italic',
    fontSize: '40px',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    marginTop: '40px',
  },
  animationContainer: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1,
  },
  logoutButton: {
    paddingTop: '60px',
  },
}));

export interface AssessmentFinishPageProps {}

const AssessmentFinishPage: React.FC<AssessmentFinishPageProps> = ({}) => {
  const classes = useStyles();
  let navigate = useNavigate();
  const nextStudyProgress = useRecoilValue(nextStudyProgressState);
  const [showAnimation, setShowAnimation] = useState(false);
  const nextCard = useRecoilValue(nextCardState);
  const setUser = useSetRecoilState(userState);
  const onSignOutError = () => {};
  const onSignOutOk = () => {
    setUser(O.none);
    navigate('/signin');
  };

  const user = useRecoilValue(userState);
  const enableUserPerformance = O.toNullable(user)?.partner.enableUserPerformance;

  const setToast = useError(true);
  const sendSignOutRequestTask = () =>
    pipe(
      AuthService.signOut(),
      TE.mapLeft(setToast),
      T.map(E.fold(onSignOutError, onSignOutOk)),
    );
  const signOut = async () => {
    const task = sendSignOutRequestTask();
    await executeTask(task);
  };
  useShortcuts(['L'], signOut, {
    shift: true,
    alt: false,
    ctrl: false,
    meta: false,
  });

  const {doneCards, tomorrowCards} = useMemo(
    () =>
      pipe(
        nextStudyProgress,
        O.map((s: NextStepProgressWithReview) => ({
          doneCards:
            s.progress.Review.total - s.progress.Review.done === 0
              ? s.progress.Review.done
              : s.progress.Main.done,
          tomorrowCards: s.tomorrow,
        })),
        O.getOrElse(() => ({doneCards: 0, tomorrowCards: 0})),
      ),
    [nextStudyProgress],
  );

  const cardsToReview: number = useMemo(
    () => StatService.getReviewCardNumber(nextStudyProgress),
    [nextStudyProgress],
  );

  const nextCardExists = typeof O.toUndefined(nextCard) !== 'undefined';

  const youJustBurnedMessage = `You just burned through ${doneCards} card${
    doneCards > 1 ? 's' : ''
  }!`;
  const niceWorkMessage =
    'nice work' +
    pipe(
      user,
      O.chain((u: User) => u.firstName),
      O.fold(
        () => '!',
        (firstName: string) => (firstName.length > 0 ? `, ${firstName}!` : '!'),
      ),
    );

  const getCongratulationText = () => {
    if (tomorrowCards === 0) {
      return 'Tomorrow, enjoy the day off!<br>You know all the content, so there’s nothing to review!';
    } else if (tomorrowCards === 1) {
      return `Next time, you have 1 card,<br>which will only take a minute or so to flip through.`;
    } else if (tomorrowCards <= 14) {
      return `Next time, you have ${tomorrowCards} cards,<br>which will only take a minute or so to flip through.`;
    } else if (tomorrowCards <= 29) {
      return `Next time, you have ${tomorrowCards} cards,<br>which will only take a minute or two to flip through.`;
    } else if (tomorrowCards <= 49) {
      return `Next time, you have ${tomorrowCards} cards,<br>which will only take a few minutes to flip through.`;
    } else {
      return `Next time, you have ${tomorrowCards} cards,<br>and you’ll be through them in no time!`;
    }
  };

  const makeInnerHtml = (str: string) => {
    return {__html: str};
  };

  useEffect(() => {
    if (O.isNone(nextStudyProgress)) {
      navigate('/welcomeBack');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  useEffect(() => {
    document.title = 'Congratulation – Blank Slate';
  }, []);

  const windowSize = useWindowSize();

  const Animation = useCallback(
    () => (
      <div className={classes.animationContainer}>
        <BSAnimation
          animationType={AnimationTypeEnum.Complete}
          seed={Date.now()}
          size={windowSize}
          onComplete={() => setShowAnimation(false)}
        />
      </div>
    ),
    [windowSize, classes.animationContainer],
  );

  const onGetStartedClick = useCallback(() => {
    navigate('/study');
  }, [navigate]);

  return (
    <div className={styles.root}>
      {showAnimation ? (
        <Animation />
      ) : (
        <>
          {enableUserPerformance ? (<PerformancePage />)  : (
            <>
              <div className={classes.viewYouJustBurned}>
                {youJustBurnedMessage}
              </div>
              <div
                className={classes.viewMessage}
                dangerouslySetInnerHTML={makeInnerHtml(getCongratulationText())}
              />
              <div className={classes.viewSeeYou}>See you then!</div>
              <div className={classes.logoutButton}>
                {nextCardExists ? (
                  <NextButton
                    halfSized
                    text={`Review ${cardsToReview} more`}
                    onClick={onGetStartedClick}
                  />
                ) : (
                  <NextButton
                    shortcutBadge="Shift + L"
                    halfSized
                    text="Logout"
                    onClick={() => {
                      signOut();
                    }}
                  />
                )}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default AssessmentFinishPage;
