import {IO} from 'fp-ts/lib/IO';
import * as TE from 'fp-ts/lib/TaskEither';
import {BSTask} from '../model/types';
import {pipe} from 'fp-ts/function';
import * as T from 'fp-ts/Task';
import * as E from 'fp-ts/Either';
import { UnknownError } from '../api/RestService';
import * as Sentry from "@sentry/react";

export const BSTaskFromIO = <A>(io: IO<A>): BSTask<A> =>
  pipe(TE.fromIO(io), TE.mapLeft(() => {
    Sentry.captureMessage('BSTaskFromIO unknown error',{
      level: 'error',
      extra: {
        io
      },
    });

    return UnknownError
  }));

export const BSTaskVoid: BSTask<void> = BSTaskFromIO(() => {});

export function ignoreTaskEither<A, E>(t: TE.TaskEither<E, A>): T.Task<void> {
  return pipe(
    t,
    T.map(
      E.fold(
        () => {},
        () => {},
      ),
    ),
  );
}

export const executeTask = async <A>(task: T.Task<A>) => {
  await task();
};
